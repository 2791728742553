<template>
    <div>
        <div class="order-header">
            <h2>
                Schedule Order Data

                <b-badge variant="success" class="ms-2 p-2 text-white">
                    <span class="small">Base Value : {{ formatBaseValue() }} </span>
                </b-badge>
            </h2>

            <div class="order-row">
                <div v-if="showFilters" class="order-controls">
                    <div class="order-group">
                        <label for="date-select">Date:</label>
                        <input type="date" v-model="selectedDate" id="date-select" @change="streamData">
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Status:</label>
                        <select v-model="selectedStatus" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Status</option>
                            <option value="all">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Submitted">Submitted</option>
                            <option value="Abandoned">Abandoned</option>
                        </select>
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Right:</label>
                        <select v-model="selectedRight" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Right</option>
                            <option value="all">All</option>
                            <option value="C">CALL</option>
                            <option value="P">PUT</option>
                        </select>
                    </div>
                </div>

                <button class="btn btn-primary" @click="toggleFilters">
                    <svg v-if="!showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    <svg v-if="showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>
            </div>
        </div>

        <hr />

        <div>
            <div class="data-table-container mb-5" @scroll="handleScroll" ref="tableContainer">
                <table class="data-table">
                    <thead>
                        <tr>
                            <th style="min-width: 170px;">Order Placed Date</th>
                            <th style="min-width: 100px;">Order Date</th>
                            <th style="min-width: 80px;">Order Id</th>
                            <th style="min-width: 80px;">Parent Id</th>
                            <th style="min-width: 80px;">Order Action</th>
                            <th style="min-width: 80px;">Order Type</th>
                            <th style="min-width: 80px;">Sec Type</th>
                            <th style="min-width: 80px;">Quantity</th>
                            <th style="min-width: 80px;">Strike Base Price</th>
                            <th style="min-width: 80px;">Strike Price</th>
                            <th style="min-width: 80px;">Strike Matched Price</th>
                            <th style="min-width: 80px;">Right</th>
                            <th style="min-width: 80px;">Order Status</th>
                            <th style="min-width: 80px;">Select a Duration (in MINUTES)</th>
                        </tr>
                    </thead>
                    <tbody v-if="!tableLoading">
                        <tr v-for="(item) in data" :key="item._id"
                            :class="(item.right === 'P' ? 'putRow' : 'callRow')">
                            <td style="min-width: 170px;">{{
                                formatDate(item.created_at) }}</td>
                            <td style="min-width: 100px;">
                                {{ item.expiry !== 'Invalid date' ? `${item.expiry.slice(4, 6)}/${item.expiry.slice(6,
                                    8)}/${item.expiry.slice(0, 4)}` : item.expiry }}
                            </td>
                            <td style="min-width: 80px;">{{ item.orderId || "N/A" }}</td>
                            <td style="min-width: 80px;">{{ item.parentId || "N/A" }}</td>
                            <td style="min-width: 80px;">{{ item.orderAction }}</td>
                            <td style="min-width: 80px;">{{ item.orderType }}</td>
                            <td style="min-width: 80px;">{{ item.secType }}</td>
                            <td style="min-width: 80px;">{{ item.quantity }}</td>
                            <td style="min-width: 80px;">{{ item.strikeBaseValue || 'N/A' }}</td>
                            <td style="min-width: 80px;">{{ item.strike }}</td>
                            <td style="min-width: 80px;">{{ item.strikeMatchedValue || 'N/A' }}</td>
                            <td style="min-width: 80px;">{{ item.right ? (item.right === "P" ? 'PUT' : 'CALL') : '-' }}</td>
                            <td style="min-width: 80px;">{{ item.orderStatus }}</td>
                            <td style="min-width: 80px;">{{ item.timeDuration }}</td>
                        </tr>
                        <tr v-if="data.length === 0">
                            <td colspan="14" style="text-align: center; padding: 20px">No Schedule Data Available</td>
                        </tr>
                    </tbody>
                    <tbody v-if="tableLoading">
                        <tr>
                            <td colspan="14" style="text-align: center; padding: 20px">
                                <b-spinner small></b-spinner>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import reconnectMainSocket, { reconnectSecondarySocket } from '@/plugins/socket';
import { convertToUTC, getUserTimezone } from '@/utils/dateUtils';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

export default {
    name: 'OrderData',
    data() {
        return {
            data: [],
            latestData: null,
            socketMain: null,
            socketSecondary: null,
            selectedDate: '',
            selectedStatus: '',
            selectedRight: '',
            showFilters: false,
            tableLoading: false
        };
    },
    computed: {},
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters;
            this.resetFilters();
            this.streamData();
        },

        resetFilters() {
            this.selectedDate = '';
            this.selectedStatus = '';
            this.selectedRight = '';
        },

        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        async streamData() {
            this.tableLoading = true
            let inputObj = {}
            const userTimezone = getUserTimezone();
            const localTime = DateTime.local().toISO();

            console.log("streamData userTimezone", userTimezone, localTime)

            if (this.selectedDate) {
                inputObj.date =  convertToUTC(this.selectedDate, userTimezone)
            } else {
                inputObj.date = convertToUTC(localTime, userTimezone)
            }

            if (this.selectedStatus) {
                inputObj.orderStatus = this.selectedStatus
            }

            if (this.selectedRight) {
                inputObj.orderRight = this.selectedRight
            }

            console.log("streamData inputObj", inputObj)

            try {
                // Emit the first chunk of data
                this.socketMain.emit('scheduleOrderData', inputObj, (response) => {
                    this.data = response?.data?.data || [];
                });
            } catch (error) {
                console.error('Error streaming data:', error);
            } finally {
                this.tableLoading = false;
            }
        },

        formatDate(date) {
            const userTimezone = getUserTimezone();
            return moment(date).tz(userTimezone).format('MM/DD/YYYY hh:mm:ss');
        },

        updatePolygonData() {
            this.socketSecondary = reconnectSecondarySocket()
            // Ensure data is not null or undefined before updating

            this.socketSecondary.on('polygonData', data => {
                if (data) {
                    // Deep clone the data to avoid direct mutation
                    this.latestData = { ...data };

                    // Optional: Log the updated base value for debugging
                    console.log("Updated base value:", this.latestData.baseValue);
                }
            })

        },

        formatBaseValue() {
            return parseFloat(this.latestData?.baseValue).toFixed(2) ?? 'N/A';
        }
    },
    mounted() {
        this.socketMain = reconnectMainSocket();
        
        this.streamData()
        this.updatePolygonData()

        this.socketMain.on('scheduleOrderStatus', data => {
            if (data) {
                this.streamData()
            }
        })
    },
};
</script>

<style scoped>
h2 {
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 15px;
}

svg {
    margin-bottom: 4px;
}

.order-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.order-controls {
    display: flex;
    gap: 15px;
    align-items: flex-end;
    justify-content: space-between;
}

.order-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
}

.order-group {
    display: flex;
    flex-direction: column;
}

.order-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.order-group input,
.order-group select {
    padding: 6px;
}

#type-select,
#start-difference,
#end-difference {
    width: 135px;
}

.search-button:hover {
    background-color: #0056b3;
}

/* Scrollable table styles */
.data-table-container {
    width: 100%;
    height: 600px;
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
}

.data-table {
    width: 100%;
    height: 100%;
}

.data-table thead {
    height: 30px;
}

.data-table tbody {
    height: 485px;
    padding-top: 30px;
}

.data-table th {
    text-align: center;
}

.data-table th,
.data-table td {
    width: auto;
    padding: 8px;
    outline: 1px solid #ddd;
}

/* Sticky header */
.data-table th {
    background-color: yellow;
    position: sticky;
    top: 0;
    z-index: 2;
}

/* Striped rows */
.data-table tbody .putRow {
    background-color: #FFCCCC;
    /* Light gray for even rows */
}

.data-table tbody .callRow {
    background-color: lightgreen;
    /* Light gray for even rows */
}

.putRow {
    background-color: #FFCCCC;
}

.callRow {
    background-color: lightgreen;
}


/* Pagination controls */
.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

/* Media query for responsiveness */
@media (max-width: 1200px) {
    .order-header {
        display: block;
    }

    .order-row {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
