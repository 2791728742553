<template>
  <div>
    <div class="filter-header">
      <h2>
        Filter SPX Data
        <b-badge v-if="!showFilters" variant="danger" class="p-1 mr-2">
          Live
        </b-badge>
      </h2>

      <div class="filter-row">
        <div v-if="showFilters" class="filter-controls">
          <div class="filter-group">
            <label for="date-select">Date:</label>
            <input type="date" v-model="selectedDate" id="date-select" @change="getData">
          </div>

          <div v-if="selectedDate !== ''" class="filter-group">
            <label for="start-time">Start Time:</label>
            <input type="time" v-model="startTime" id="start-time" :min="minTime" :max="maxTime" @change="getData">
          </div>

          <div v-if="selectedDate !== ''" class="filter-group">
            <label for="end-time">End Time:</label>
            <input type="time" v-model="endTime" id="end-time" :min="minTime" :max="maxTime" @change="validateTimes">
          </div>

          <div class="filter-group">
            <label for="type-select">Type Difference:</label>
            <select v-model="selectedType" id="type-select" @change="getData">
              <option disabled value="">Select Type Difference</option>
              <option value="all">All</option>
              <option value="call">Call</option>
              <option value="put">Put</option>
            </select>
          </div>

          <!-- <div v-if="selectedType !== ''" class="filter-group">
            <label for="difference-select">Difference:</label>
            <select v-model="selectedDifference" id="difference-select" @change="getData">
              <option disabled value="">Select Difference</option>
              <option v-for="d in differences" :key="d" :value="d">
                {{ d }}
              </option>
            </select>
          </div> -->

          <div v-if="selectedType !== ''" class="filter-group">
            <label for="start-difference">Start Difference:</label>
            <input type="number" step="0.01" v-model="startDifference" id="start-difference" :min="0" @change="getData">
          </div>

          <div v-if="selectedType !== ''" class="filter-group">
            <label for="end-difference">End Difference:</label>
            <input type="number" step="0.01" v-model="endDifference" id="end-difference" :min="startDifference"
              @change="getData">
          </div>
        </div>

        <button class="btn btn-primary" @click="toggleFilters">
          <svg v-if="!showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-search" viewBox="0 0 16 16">
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
          <svg v-if="showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-x-lg" viewBox="0 0 16 16">
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>

        <div v-if="showFilters">
          <button v-if="!downloadLoading" class="btn btn-primary" @click="downloadData">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" fill="currentColor"
              class="bi bi-cloud-download" viewBox="0 0 16 16">
              <path
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
              <path
                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
            </svg>
          </button>
          <button v-if="downloadLoading" class="btn btn-primary" disabled>
            <b-spinner small></b-spinner>
          </button>
        </div>
      </div>
    </div>

    <hr />

    <div>
      <div v-if="showFilters" class="float-end mb-3">
        <label for="items-per-page-select">Select Data Per Page :</label>
        <select v-model="itemsPerPage" class="ms-2 p-2" id="items-per-page-select" @change="selectDataPerPage">
          <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>

      <div class="data-table-container" @scroll="handleScroll" ref="tableContainer">
        <table class="data-table">
          <thead>
            <tr>
              <th style="min-width: 100px;" class="sticky-col sticky-col-th-1">Date</th>
              <th style="min-width: 110px;" class="sticky-col sticky-col-th-2">Time</th>
              <th style="min-width: 100px;">Base Value</th>
              <th>Call</th>
              <!-- <th>Call 1</th> -->
              <th>Put</th>
              <th>Put 1</th>
              <th style="min-width: 80px;">Call Ask Price</th>
              <!-- <th style="min-width: 80px;">Call Ask Price 1</th> -->
              <th style="min-width: 80px;">Call Put Ask Price</th>
              <th style="min-width: 80px;">Call Put Ask Price 1</th>
              <th style="min-width: 80px;">Call Difference</th>
              <!-- <th style="min-width: 80px;">Call Difference 1</th> -->
              <th style="min-width: 80px;">Put Difference</th>
              <!-- <th style="min-width: 80px;">Put Difference 1</th> -->
              <th style="min-width: 80px;">Call (put)</th>
              <th style="min-width: 80px;">Call 1 (put)</th>
              <th style="min-width: 80px;">Put (put)</th>
              <!-- <th style="min-width: 80px;">Put 1 (put)</th> -->
              <th style="min-width: 80px;">Call Value (put)</th>
              <th style="min-width: 80px;">Call Value 1 (put)</th>
              <th style="min-width: 80px;">Put Value (put)</th>
              <!-- <th style="min-width: 80px;">Put Value 1 (put)</th> -->
            </tr>
          </thead>
          <tbody v-if="!tableLoading">
            <tr v-for="(item, index) in data" :key="item.time" :class="(index % 2 === 0 ? 'evenRow' : 'oddRow')">
              <td style="min-width: 100px;" class="sticky-col sticky-col-td-1">{{ item.date }}</td>
              <td style="min-width: 110px;" class="sticky-col sticky-col-td-2">{{ item.time }}</td>
              <td style="min-width: 100px;">{{ Number(item.baseValue).toFixed(2) }}</td>
              <td>{{ item.call }}</td>
              <!-- <td>{{ item.call1 }}</td> -->
              <td>{{ item.callput }}</td>
              <td>{{ item.callput1 }}</td>
              <td style="min-width: 80px;">{{ item.callAskPrice }}</td>
              <!-- <td style="min-width: 80px;">{{ item.callAskPrice1 }}</td> -->
              <td style="min-width: 80px;">{{ item.callputAskPrice }}</td>
              <td style="min-width: 80px;">{{ item.callputAskPrice1 }}</td>
              <td style="background-color: #FFCCCC; min-width: 80px;">{{ Number(item.callaskPriceDifference).toFixed(2)
                }}</td>
              <!-- <td style="background-color: #FFCCCC; min-width: 80px;">{{ Number(item.callaskPriceDifference1).toFixed(2)
                }}</td> -->
              <td style="background-color: lightgreen; min-width: 80px;">{{
                Number(item.putaskPriceDifference).toFixed(2) }}</td>
              <!-- <td style="background-color: lightgreen; min-width: 80px;">{{
                Number(item.putaskPriceDifference1).toFixed(2) }}</td> -->
              <td style="min-width: 80px;">{{ item.putcall }}</td>
              <td style="min-width: 80px;">{{ item.putcall1 }}</td>
              <td style="min-width: 80px;">{{ item.put }}</td>
              <!-- <td style="min-width: 80px;">{{ item.put1 }}</td> -->
              <td style="min-width: 80px;">{{ item.putcallAskPrice }}</td>
              <td style="min-width: 80px;">{{ item.putcallAskPrice1 }}</td>
              <td style="min-width: 80px;">{{ item.putAskPrice }}</td>
              <!-- <td style="min-width: 80px;">{{ item.putAskPrice1 }}</td> -->
            </tr>
            <tr v-if="data.length == 0">
              <td colspan="23" style="text-align: center; padding: 20px">No Data Available</td>
            </tr>
          </tbody>
          <tbody v-if="tableLoading">
            <tr>
              <td colspan="23" style="text-align: center; padding: 20px">
                <b-spinner small></b-spinner>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="showFilters" class="pagination-controls mt-3">
        <div class="filter-group me-2">
          <select v-model="itemsPerPage" id="items-per-page-select" @change="selectDataPerPage">
            <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </div>
        <button class="btn btn-primary" @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
        <button class="btn btn-primary" @click="nextPage"
          :disabled="currentPage === totalPages || totalPages === 0">Next</button>
      </div>
    </div>
    <div class="bottom-padding"></div>
  </div>
</template>

<script>
import _ from 'lodash';
import { reconnectSecondarySocket } from '@/plugins/socket';
import moment from 'moment-timezone';
import { useToast } from 'vue-toast-notification';

// Initialize toast notifications
const $toast = useToast();

export default {
  name: 'DataFilter',
  data() {
    return {
      data: [],
      socket: null,
      totalData: 0,
      selectedDate: '',
      startTime: '',
      endTime: '',
      minTime: '00:00',
      maxTime: '23:59',
      selectedType: '',
      selectedDifference: '',
      startDifference: 0,
      endDifference: 0,
      differences: Array.from({ length: 21 }, (_, i) => (i * 0.5).toFixed(2)),
      showFilters: false,
      itemsPerPage: 50,
      currentPage: 1,
      itemsPerPageOptions: [50, 100, 250, 500, 1000, 2500, 5000, 10000],
      tableLoading: false,
      downloadLoading: false,
      fullData: [],
      chunkedData: [],
      currentChunkIndex: 0,
      chunkSize: 15,
      allDataLoaded: false
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalData / this.itemsPerPage);
    }
  },
  methods: {
    async streamData() {
      this.socket = reconnectSecondarySocket()
      this.tableLoading = true

      const params = new URLSearchParams();
      if (this.selectedDate) params.append('date', moment(this.selectedDate, 'YYYY-MM-DD').format('MM/DD/YYYY'));

      const queryString = new URLSearchParams(params).toString();
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/getAllData?${queryString}`;

      const response = await fetch(url);
      if (response.ok) {
        const jsonData = await response.json();
        this.fullData = jsonData?.data?.data || [];
        this.chunkedData = _.chunk(this.fullData, this.chunkSize)
        this.loadNextChunk()

        this.socket.on('polygonData', async newData => {
          if (!this.showFilters) {
            this.fullData.unshift(newData)
            this.chunkedData = _.chunk(this.fullData, this.chunkSize);

            if (this.currentChunkIndex > 0) {
              // Insert new data into the first chunk being displayed
              this.data.unshift(newData);
              if (this.data.length > this.chunkSize) {
                this.data.pop(); // Maintain the chunk size to prevent UI lag
              }
            } 
            
            // else if (this.data.length === 0) {
            //   this.loadNextChunk()
            // }
          }
        });
      }

      this.tableLoading = false
    },
    loadNextChunk() {
      if (!this.showFilters && this.currentChunkIndex < this.chunkedData.length) {
        this.data = [...this.data, ...this.chunkedData[this.currentChunkIndex]];
        this.currentChunkIndex += 1;
      } else {
        this.allDataLoaded = true;
      }
    },
    handleScroll() {
      const container = this.$refs.tableContainer;

      // Load the next chunk when scrolled near the bottom
      if (!this.showFilters && container.scrollTop + container.clientHeight >= container.scrollHeight - 10 && !this.allDataLoaded) {
        this.loadNextChunk();
      }
    },
    async getData() {
      this.tableLoading = true
      const params = new URLSearchParams();

      const applyTime = (date, time) => {
        const [hour, minute] = time.split(":");
        return moment(date).set({
          hour: parseInt(hour, 10),
          minute: parseInt(minute, 10),
          second: 0,
          millisecond: 0
        }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      };

      if (this.selectedDate) {
        params.append('date', moment(this.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
      } else if (this.selectedDate === "") {
        const now = moment().tz(process.env.VUE_APP_TIMEZONE)
        const currentDate = now.format('YYYY-MM-DD');
        const yesterday = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

        // Ensure START_HOUR and START_MINUTE are properly cast to numbers
        const scheduledStartTime = moment.tz({
          hour: process.env.VUE_APP_START_HOUR,
          minute: process.env.VUE_APP_START_MINUTE,
        }, process.env.VUE_APP_TIMEZONE);

        // Check if the current time is greater than the scheduled start time
        const isAfterScheduledTime = now.isAfter(scheduledStartTime);

        if (isAfterScheduledTime) {
          // console.log('The time is greater than the scheduled start time');
          this.selectedDate = currentDate;
          this.startTime = '09:00';
          this.endTime = '16:30';
        } else {
          // console.log('The time is before the scheduled start time');
          this.selectedDate = yesterday;
          this.startTime = '09:00';
          this.endTime = '16:30';
        }


        this.tableLoading = false

        return $toast.open({
          message: 'Date is required field',
          type: 'error',
          duration: 3000,
          dismissible: true,
          queue: true,
        });
      }

      if (this.startTime) {
        // console.log('this.startTime', this.startTime)
        params.append('startTime', applyTime(this.selectedDate, this.startTime));
      }

      if (this.endTime) {
        params.append('endTime', applyTime(this.selectedDate, this.endTime));
      }

      if (this.selectedType) {
        params.append('type', this.selectedType);
      }

      if (this.selectedDifference) {
        params.append('difference', this.selectedDifference);
      }

      if (this.startDifference) {
        params.append('startDifference', this.startDifference);
        // console.log('startDifference', this.startDifference)
      }

      if (this.endDifference) {
        params.append('endDifference', this.endDifference);
        // console.log('endDifference', this.endDifference)
      }

      if (this.currentPage) {
        params.append('skip', this.currentPage);
      }

      if (this.itemsPerPage) {
        params.append('limit', this.itemsPerPage)
      }

      const queryString = new URLSearchParams(params).toString();
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/getData?${queryString}`;

      try {
        const response = await fetch(url);
        if (response.ok) {
          const jsonData = await response.json();
          this.data = jsonData.data.data;
          this.totalData = jsonData.data.count;
        } else {
          console.error('Failed to fetch data:', response.status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      this.tableLoading = false
    },
    async downloadData() {
      this.downloadLoading = true
      const params = new URLSearchParams();

      const applyTime = (date, time) => {
        const [hour, minute] = time.split(":");
        return moment(date).set({
          hour: parseInt(hour, 10),
          minute: parseInt(minute, 10),
          second: 0,
          millisecond: 0
        }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      };

      if (this.selectedDate) params.append('date', moment(this.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));

      if (this.startTime) {
        // console.log('this.startTime', this.startTime);
        params.append('startTime', applyTime(this.selectedDate, this.startTime));
      }

      if (this.endTime) {
        params.append('endTime', applyTime(this.selectedDate, this.endTime));
      }

      if (this.selectedType) {
        params.append('type', this.selectedType);
      }

      if (this.selectedDifference) {
        params.append('difference', this.selectedDifference);
      }

      if (this.startDifference) {
        params.append('startDifference', this.startDifference);
        // console.log('startDifference', this.startDifference);
      }

      if (this.endDifference) {
        params.append('endDifference', this.endDifference);
        // console.log('endDifference', this.endDifference);
      }

      if (this.currentPage) {
        params.append('skip', this.currentPage);
      }

      if (this.itemsPerPage) {
        params.append('limit', this.itemsPerPage);
      }

      const queryString = params.toString();
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/downloadSpxData?${queryString}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      // a.download = 'spxData.xlsx';
      a.download = `spx_data_${moment(this.selectedDate).format('MM-DD-YYYY')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);

      this.downloadLoading = false
    },
    toggleFilters() {
      const now = moment().tz(process.env.VUE_APP_TIMEZONE);
      const endTime = moment.tz({ hour: process.env.VUE_APP_END_HOUR, minute: process.env.VUE_APP_END_MINUTE }, process.env.VUE_APP_TIMEZONE);
      const scheduledStartTime = moment.tz({ hour: process.env.VUE_APP_START_HOUR, minute: process.env.VUE_APP_START_MINUTE }, process.env.VUE_APP_TIMEZONE);
      const currentDate = now.format('YYYY-MM-DD');
      const yesterday = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

      if (this.showFilters) {
        this.selectedDate = ""
        this.startTime = ""
        this.endTime = ""
        this.selectedType = ""
        this.selectedDifference = ""
        this.currentPage = 1
        this.data = []
      } else {
        if (now.isAfter(scheduledStartTime) && now.isBefore(endTime)) {
          this.selectedDate = currentDate;
          this.startTime = '09:00';
          this.endTime = '16:30';
        } else {
          if(!now.isBefore(endTime)) {
            this.selectedDate = currentDate;
            this.startTime = '09:00';
            this.endTime = '16:30';
          }else {
            this.selectedDate = yesterday;
            this.startTime = '09:00';
            this.endTime = '16:30';
          }
        }

        this.getData();
      }

      this.showFilters = !this.showFilters;
    },
    selectDataPerPage() {
      this.currentPage = 1
      this.getData()
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.getData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.getData();
      }
    },
    validateTimes() {
      if (this.startTime && this.endTime) {
        if (this.endTime <= this.startTime) {
          $toast.open({
            message: 'End time must be greater than start time.',
            type: 'error',
            duration: 3000,
            dismissible: true,
            queue: true,
          });

        } else {
          this.getData()
        }
      }
    },
  },
  mounted() {
    const now = moment().tz(process.env.VUE_APP_TIMEZONE);
    const scheduledStartTime = moment.tz({ hour: process.env.VUE_APP_START_HOUR, minute: process.env.VUE_APP_START_MINUTE }, process.env.VUE_APP_TIMEZONE);
    const endTime = moment.tz({ hour: process.env.VUE_APP_END_HOUR, minute: process.env.VUE_APP_END_MINUTE }, process.env.VUE_APP_TIMEZONE);
    const currentDate = now.format('YYYY-MM-DD');
    const yesterday = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

    if (now.isAfter(scheduledStartTime) && now.isBefore(endTime)) {
      this.selectedDate = currentDate;
      this.streamData()
      this.startTime = '09:00';
      this.endTime = '16:30';
    } else {
      this.showFilters = true
      if (!now.isBefore(endTime)) {
        this.selectedDate = currentDate;
        this.startTime = '09:00';
        this.endTime = '16:30';
      } else {
        this.selectedDate = yesterday;
        this.startTime = '09:00';
        this.endTime = '16:30';
      }
      this.getData();
    }
  }
};
</script>


<style scoped>
h2 {
  font-size: 22px;
  margin-bottom: 0px;
  margin-right: 15px;
}

svg {
  margin-bottom: 4px;
}

.filter-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.filter-controls {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  align-items: flex-end;
  justify-content: space-between;
}

.filter-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 15px;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-group input,
.filter-group select {
  padding: 6px;
}

#type-select,
#start-difference,
#end-difference {
  width: 135px;
}

.search-button:hover {
  background-color: #0056b3;
}

/* Scrollable table styles */
.data-table-container {
  width: 100%;
  height: 512px;
  overflow-y: auto;
  margin-top: 20px;
  border: 1px solid #ddd;
}

.data-table {
  width: 100%;
  height: 100%;
}

.data-table thead {
  height: 80px;
}

.data-table tbody {
  height: 400px;
  padding-top: 80px;
}

.data-table th {
  text-align: center;
}

.data-table th,
.data-table td {
  width: auto;
  padding: 8px;
  outline: 1px solid #ddd;
}

/* Sticky header */
.data-table th {
  background-color: yellow;
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Striped rows */
.data-table tbody .evenRow {
  background-color: #E0E0E0;
  /* Light gray for even rows */
}

.data-table tbody .oddRow {
  background-color: #FFFFFf;
  /* Light gray for even rows */
}

.sticky-col th {
  z-index: 3;
}

/* Sticky first two columns */
.sticky-col {
  position: sticky;
  z-index: 1;
}

.evenRow .sticky-col {
  background-color: #E0E0E0;
}

.oddRow .sticky-col {
  background-color: #FFFFFf;
}

.sticky-col-th-1 {
  left: 0;
  z-index: 3 !important;
}

.sticky-col-th-2 {
  left: 100px;
  z-index: 3 !important;
}

.sticky-col-td-1 {
  left: 0;
  z-index: 1 !important;
}

.sticky-col-td-2 {
  left: 100px;
  z-index: 1 !important;
}

/* Pagination controls */
.pagination-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

/* Media query for responsiveness */
@media (max-width: 1200px) {
  .filter-header {
    display: block;
  }

  .filter-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>