<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead class="thead-dark"></thead>
      <tbody>
        <tr v-if="latestData">
          <td rowspan="1" colspan="6" style="text-align: center; font-weight: bold; background-color: #dbdbdb;">
            <div style="height: 100%; display: flex; justify-content: flex-start; align-items: center;">
              <h5>{{ side === 'CALL' ? 'PUT' : 'CALL' }} Limit</h5>
            </div>
          </td>
        </tr>

        <tr v-if="latestData && side === 'PUT'">
          <td></td>
          <td></td>
          <td>{{ Number(latestData.putcall1).toFixed(2) }}</td>
          <td>CALL</td>
          <td>{{ latestData.putcallAskPrice1 ? Number(latestData.putcallAskPrice1).toFixed(2) : '-' }}</td>
          <td rowspan="3" style="text-align: center; vertical-align: middle;">
            {{ latestData?.putaskPriceDifference !== null ? Number(latestData?.putaskPriceDifference).toFixed(2) : '-' }}
          </td>
        </tr>

        <tr v-if="latestData">
          <td>{{ latestData.date }}</td>
          <td>{{ latestData.time }}</td>
          <td>{{ side === 'CALL' ? Number(latestData.call).toFixed(2) : Number(latestData.putcall).toFixed(2) }}</td>
          <td>CALL</td>
          <td>{{ side === 'CALL' ? (latestData.callAskPrice ? Number(latestData.callAskPrice).toFixed(2) : '-') : (latestData.putcallAskPrice ? Number(latestData.putcallAskPrice).toFixed(2) : '-') }}</td>
          <td v-if="side === 'CALL'" rowspan="3" style="text-align: center; vertical-align: middle;">
            {{ side === 'CALL' ? (latestData?.callaskPriceDifference !== null ?
              Number(latestData?.callaskPriceDifference).toFixed(2) : '-') : (latestData?.putaskPriceDifference !== null ?
                Number(latestData?.putaskPriceDifference).toFixed(2) : '-') }}
          </td>
        </tr>

        <tr v-if="latestData">
          <td>{{ latestData.dayName }}</td>
          <td>{{ Number(latestData.baseValue).toFixed(2) }}</td>
          <td>{{ side === 'CALL' ? Number(latestData.callput).toFixed(2) : Number(latestData.put).toFixed(2) }}</td>
          <td>PUT</td>
          <td>{{ side === 'CALL' ? (latestData.callputAskPrice ? Number(latestData.callputAskPrice).toFixed(2) : '-') : (latestData.putAskPrice ? Number(latestData.putAskPrice).toFixed(2) : '-') }}</td>
        </tr>

        <tr v-if="latestData && side === 'CALL'">
          <td></td>
          <td></td>
          <td>{{ Number(latestData.callput1).toFixed(2) }}</td>
          <td>PUT</td>
          <td>{{ latestData.callputAskPrice1 ? Number(latestData.callputAskPrice1).toFixed(2) : '-' }}</td>
        </tr>

        <!-- <tr>
          <td colspan="6">
            <div style="width: auto; float: right;">
              <span>Select {{ side }} Limit : </span>
              <select :id="side.toLowerCase()" v-model="localSelectedLimit" @change="handleLimitChange">
                <option v-for="limit in limits" :key="limit" :value="limit">{{ limit }}</option>
              </select>
            </div>
          </td>
        </tr> -->
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    side: String,
    latestData: Object,
    selectedLimit: String,
    limits: Array,
  },
  data() {
    return {
      localSelectedLimit: this.selectedLimit,
    };
  },
  watch: {
    selectedLimit(newVal) {
      this.localSelectedLimit = newVal;
    },
  },
  methods: {
    handleLimitChange(event) {
      event.preventDefault();
      this.$emit('update:selectedLimit', this.localSelectedLimit);
    },
    convertToJamaicaTime(value, format) {
      return moment(value).format(format);
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
