<template>
    <div>
        <div v-if="this.role === 'admin'">
            <AppHeader />
        </div>

        <div class="container mt-5" v-if="this.role === 'admin'">
            <FilterData />
        </div>
    </div>
  </template>
  
  <script>
  import VueCookie from 'vue-cookie';
  import AppHeader from '@/components/AppHeader.vue';
  import FilterData from '@/components/FilterData.vue';
  
  export default {
    name: "SpxDataPage",
    components: {
        AppHeader,
        FilterData
    },
    data() {
        return {
            role: ''
        }
    },
    mounted() {
        this.role = VueCookie.get('userRole') || 'user'

        if (VueCookie.get('authStatus') !== "true") {
            this.$router.push('/')
        }

        if (VueCookie.get('authStatus') === "true") {
            if (VueCookie.get('userRole') !== 'admin') {
                this.$router.push(`/${VueCookie.get('allowedIndex').split(',')[0]}`)
            }
        }
    }
  };
  </script>
  
  <style scoped>
  /* Your styles here */
  </style>
  