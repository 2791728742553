<template>
    <header class="header">
        <div class="header-left d-flex justify-content-center align-items-center">
            <span>{{this.homePage.toUpperCase()}}</span>
        </div>
        <div class="header-right">
            <a :href="'/'+this.homePage" class="header-link">Home</a>
            <a v-if="this.role === 'admin'" href="/spxData" class="header-link">Spx Data</a>
            <a v-if="this.role === 'admin'" href="/user" class="header-link">Users</a>
            <!-- <a v-if="this.role === 'admin'" href="/orderSetting" class="header-link">Settings</a> -->
            <a href="#" class="header-link" @click="logout">Logout</a>
        </div>
    </header>
</template>

<script>
import VueCookie from 'vue-cookie';

export default {
    name: 'AppHeader',
    data() {
        return {
            role: 'user',
            homePage: VueCookie.get('allowedIndex').split(',')[0] || 'spx',
        }
    },
    mounted() {
        this.role = VueCookie.get('userRole') || 'user'
    },
    methods: {
        logout() {
            VueCookie.delete('authToken')
            VueCookie.set('authStatus')
            this.$router.push('/')
        },
    }
};
</script>

<style scoped>
a {
    color: white !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: grey;
    color: white;
    border-bottom: 1px solid #ddd;
    padding-right: 5rem;
    padding-left: 5rem;
}

.header-left {
    font-size: 1.5rem;
    font-weight: bold;
}

.header-right {
    display: flex;
    gap: 1rem;
}

.header-link {
    text-decoration: none;
    color: #007bff;
}

.header-link:hover {
    text-decoration: underline;
}
</style>